import Slider from './Slider';
import { Controls } from '@glidejs/glide/dist/glide.modular.esm';

window.addEventListener('load', () => {
  const slider = new Slider({ element: '.glide' });
  const {autoplay, animation, displayPagination, displayArrows} = slider.getElement().dataset;

  // options
  if (autoplay) slider.addOption('autoplay', autoplay);
  if (animation) slider.addOption('animationDuration', animation);

  // extensions
  if (displayArrows || displayPagination) slider.addExtension('Controls', Controls);

  slider.init();

  slider.getGlideInstance().on('run.before', () => {
    const { index } = slider.getGlideInstance();
    const previousSlide = document.querySelectorAll('.slide')[index];

    if (previousSlide.classList.contains('slide--video')) {
      const iframe = previousSlide.querySelector('iframe');
      const overlay = previousSlide.querySelector('.slide__overlay');

      iframe.src = '';
      overlay.classList.remove('slide__overlay--hidden');
    }
  });

  const playButton = [...document.querySelectorAll('.slide__play')] || false;

  playButton && playButton.map(btn => {
    const slider = btn.closest('.slider');
    const modal = slider.querySelector('.slide__modal');
    const targetIframe = modal.querySelector('.slide__modal-source');
    const modalOverlay = modal.querySelector('.slide__modal-overlay');
    const modalClose = modal.querySelector('.slide__modal-close');

    btn.addEventListener('click', e => {
      const videoSource = e.target.dataset.videoSrc;

      targetIframe.src = '';
      targetIframe.src = videoSource;
      modal.classList.add('active');
    });

    document.addEventListener('click', e => {
      if (modalOverlay.contains(e.target) || modalClose.contains(e.target)) {
        modal.classList.remove('active');
        targetIframe.src = '';
      }
    });
  });
});
