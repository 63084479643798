import Glide from '@glidejs/glide';
import "../../scss/front.scss";
import "@glidejs/glide/src/assets/sass/glide.core.scss";

export default class Slider {
  defaultOptions = {
    type: 'carousel',
    startAt: 0,
    perView: 1,
    gap: 0
  }

  constructor({ element, debug = false }) {
    this.element = document.querySelector(element);
    this.selector = element;
    this.debug = debug;

    if (!this.element) throw new Error(`${element} does not exist.`);

    this.slider = null;
    this.extensions = {};
    this.options = {};
  }

  init() {
    this.slider = new Glide(this.selector, {...this.options, ...this.defaultOptions}).mount(this.extensions);
  }

  getElement() {
    return this.element;
  }

  getGlideInstance() {
    return this.slider;
  }

  addExtension(key, ext) {
    this.extensions[key] = ext;
  }

  addOption(key, opt) {
    this.options[key] = opt;
  }
}
